<template>
  <div>

    <div class="row">
      <loading-notification :show="loading" />
      <retry-notification
        :show="error"
        @retry="updateData()"
      />
    </div>

    <div class="row">
      <div class="flex xs12 sm4">
        <va-card :title="$t('prayers.view.title')">
          <actions
            slot="actions"
            crud-links="prayers"
            controller="PrayerRequests"
            delete-route="prayers"
            :actions="actions"
            :action-data="{id: $route.params.id}"
          />
          <text-list v-show="!loading" :title="$t('tables.headings.status')">
            <va-badge :color="getStatusColor(prayer.status)">
              {{ $t(getStatusLabel(prayer.status)) }}
            </va-badge>
          </text-list>
          <text-list :title="$t('tables.headings.title')">
            {{ prayer.title }}
          </text-list>
          <text-list
            v-if="prayer.user"
            :condition="currentUser.can('Users', 'view')"
            :title="$t('tables.headings.user')"
            :label="prayer.user.name"
            :to="{ name: 'usersView', params: {id: prayer.user.id}}"
          />
          <text-list
            v-if="prayer.user"
            :title="$t('tables.headings.email')"
          >
            {{ prayer.user.email }}
          </text-list>
          <text-list :title="$t('tables.headings.date')">
            {{ prayer.date | date }}
          </text-list>
          <text-list
            v-if="prayer.region"
            :condition="currentUser.can('Regions', 'view')"
            :title="$t('tables.headings.region')"
            :label="$t(prayer.region.name)"
            :to="{ name: 'regionsView', params: {id: prayer.region.id}}"
          />
          <text-list
            v-if="prayer.country"
            :condition="currentUser.can('Countries', 'view')"
            :title="$t('tables.headings.country')"
            :label="$t(prayer.country.name)"
            :to="{ name: 'countriesView', params: {id: prayer.country.id}}"
          />
          <text-list
            v-if="prayer.district"
            :condition="currentUser.can('Districts', 'view')"
            :title="$t('tables.headings.district')"
            :label="$t(prayer.district.name)"
            :to="{ name: 'districtsView', params: {id: prayer.district.id}}"
          />
        </va-card>
      </div>
      <div class="flex xs12 sm8">
        <div class="row">
          <div class="flex xs12">
            <va-card :title="$t('prayers.view.request')">
              <div>{{ prayer.request }}</div>
            </va-card>
          </div>
          <div class="flex xs12">
            <va-card :title="$t('prayers.view.observations')">
              <div>{{ prayer.observations }}</div>
            </va-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
const Actions = () => import(/* webpackPrefetch: true */ '@/components/extras/Actions')
const TextList = () => import(/* webpackPrefetch: true */ '@/components/extras/Text/TextList')

export default {
  name: 'prayers-view',
  components: {
    Actions, TextList,
  },
  data () {
    return {
      error: false,
      loading: false,
      prayer: {},
      countries: [],
      actions: ['index', 'new', 'edit', 'delete'],
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
  },
  created () {
    this.updateData()
  },
  methods: {
    getStatusColor (status) {
      let color = 'primary'
      switch (status) {
        case 1: color = 'success'; break
      }
      return color
    },
    getStatusLabel (status) {
      let label = 'prayers.status.pending'
      switch (status) {
        case 1: label = 'prayers.status.answered'; break
      }
      return label
    },
    // FIXME
    routeBuilder (id) {
      return `prayers/${id}`
    },
    async updateData () {
      this.loading = true
      this.error = false

      let u = false
      const prayerId = this.$route.params.id
      try {
        u = await this.$http.get(this.routeBuilder(prayerId))
      } catch (err) {
        // console.log('Error fetching prayer data', err)
        this.error = true
        this.loading = false
        return
      }

      this.loading = false
      this.prayer = { ...u.data.data }
    },
  },
}
</script>
